import authReduce from './reducers/authReducer'
import restaurantsReducer from './reducers//restaurantsReducer'
import restaurantsserviceReducer from './reducers/restaurantsserviceReducer'
import menutypeReducer from './reducers/menutypeReducer'
import kichenCommentReducer from './reducers/kitchenCommentReducer'
import siteReducer from './reducers/siteReducer'
import variantCategoryReducer from './reducers/variantCategoryReducer'
import taxSettingReducer from './reducers/taxSettingReducer'
import categoriesReducer from './reducers/categoriesReducer'
import mealCourseReducer from './reducers/mealCourseReducer'
import variantReducer from './reducers/variantReducer'
import addOnReducer from './reducers/addOnReducer'
import menuitemReducer from './reducers/menuitemReducer'
import kitchenCommentCategoryReducer from './reducers/kitchenCommentCategoryReducer'
import optionSetReducer from './reducers/optionSetReducer'
import scheduleDayReducer from './reducers/scheduleDayReducer'
import reportDepartmentReducer from './reducers/ProductItems/reportDepartmentReducer'
import serviceZoneReducer from './reducers/servicezone.Reducer'
import lookupsReducer from './reducers/lookupReducer'
import printerSettingReducer from './reducers/settings/printerSettingReducer'
import kitchenDisplayReducer from './reducers/settings/kitchenDisplayReducer'
import restaurantSettingReducer from './reducers/restaurantSetting.Reducer'
import paymentConfigurationReducer from './reducers/paymentConfiguration.Reducer'
import webSettingsReducer from './reducers/webSettings.Reducer'
import payInOutReducer from './reducers/payInOut.Reducer'
import advertisementReducre from './reducers/advertisement.Reducre'
import customersReducre from './reducers/customers.Reducre'
import ordersReducre from './reducers/orders.Reducer'
import promotionReducer from './reducers/discountPromotions/promotions.reducer';
import reportsReducer from './reducers/reports.Reducer'
import tagMasterReducer from './reducers/tagMasterReducer'
import PosUserReducer from './reducers/settings/PosUserReducer'
import PosUserGroupReducer from './reducers/settings/PosUserGroupReducer'
import staffDiscountReducer from './reducers/settings/staffDiscountReducer'
import discountReducer from './reducers/discountPromotions/discountReducer'
import webContentReducer from './reducers/webContent.Reducer'
import landingPageReducer from './reducers/landingPageReducer'
import conditionalVoucherReducer from './reducers/discountPromotions/conditionalVoucherReducer'
import roleReducer from './reducers/settings/roleReducer'
import userStaffReducer from './reducers/settings/userStaffReducer'
import restaurantTableReducer from './reducers/settings/restaurantTableReducer';
import generalSettingReducer from './reducers/settings/generalSettingReducer'
import restaurantDevicesReducer from './reducers/settings/restaurantDevicesReducer';
import areaReducer from './reducers/settings/areaReducer'
import deviceReducer from './reducers/deviceReducer/deviceReducer';
import posPaymentSettingsReducer from "./reducers/settings/PosPaymentSettingsReducer";
import posGeneralSettingReducer from './reducers/settings/posGeneralSettingsReducer'
import staffReducer from './reducers/settings/staffReducer'
import printLayoutReducer from './reducers/printLayoutReducer/printLayoutReducer'
import staffClockReducer from './reducers/staffClockReducer/staffClock.Reducer'
import cashInReportReducer from './reducers/cashInReportReducer/cashInReport.Reducer'
import cashLiftReportReducer from './reducers/cashLiftReportReducer/cashLiftReport.Reducer'
import allergenMasterReducer from './reducers/allergenMasterReducer'
import daylogReportReducer from './reducers/dayLogReportReducer/daylogReportReducer'
export default {
    authReduce,
    restaurantsReducer,
    restaurantsserviceReducer,
    menutypeReducer,
    kichenCommentReducer,
    siteReducer,
    variantCategoryReducer,
    taxSettingReducer,
    categoriesReducer,
    mealCourseReducer,
    variantReducer,
    addOnReducer,
    menuitemReducer,
    kitchenCommentCategoryReducer,
    optionSetReducer,
    scheduleDayReducer,
    reportDepartmentReducer,
    serviceZoneReducer,
    lookupsReducer,
    printerSettingReducer,
    kitchenDisplayReducer,
    restaurantSettingReducer,
    paymentConfigurationReducer,
    webSettingsReducer,
    payInOutReducer,
    advertisementReducre,
    customersReducre,
    ordersReducre,
    promotionReducer,
    reportsReducer,
    tagMasterReducer,
    allergenMasterReducer,
    PosUserReducer,
    PosUserGroupReducer,
    staffDiscountReducer,
    discountReducer,
    webContentReducer,
    landingPageReducer,
    conditionalVoucherReducer,
    roleReducer,
    userStaffReducer,
    restaurantTableReducer,
    generalSettingReducer,
    restaurantDevicesReducer,
    areaReducer,
    deviceReducer,
    posPaymentSettingsReducer,
    posGeneralSettingReducer,
    staffReducer,
    printLayoutReducer,
    staffClockReducer,
    cashInReportReducer,
    cashLiftReportReducer,
    daylogReportReducer
}